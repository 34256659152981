<template>
  <div class="syllabus">
    <Steps :current="step" :link="link" :mode="mode" :id="id"></Steps>
    <div v-if="uploadArr.length > 0">
      <FileAlert :uploadArr="uploadArr" @removeMessage="removeMessage"></FileAlert>
    </div>
    <div class="container p-4 editCourse__container">

      <div class="row">
        <div class="syllabus__group" v-for="(item, i) in syllabus" :key="item.id">
          <div class="syllabus__box syllabus__chapter d-flex align-items-center p-3 mb-2 position-relative">
            <span>章節 {{ i + 1 }}</span>
            <span class="text-start mx-2" style="flex: 1 1 0%;display: inline-block;height: 1.5rem;line-height: 1.5rem;" v-if="tempSyllabus.id !== item.id"
              @dblclick.prevent="editChapter(item);Showtooltip = ''"
              @mouseover="Showtooltip = item.id"
              @mouseleave="Showtooltip = ''">
              {{ item.title }}
            </span>
            <span class="position-absolute edit-tooltip" v-if="Showtooltip === item.id">
              點擊兩下以編輯
            </span>
            <input type="text" style="flex: 1 1 0%;border:none;"
              v-if="tempSyllabus.id == item.id" class="mx-2 px-0"
              v-model="tempSyllabus.title"
              @change="finishChapter;change = true"
              >
            <!-- <span class="mx-2"><i class="fas fa-check" @click.prevent="finishChapter"></i></span> -->
            <span class="mx-2 testhover"><i class="fas fa-plus" @click.prevent="addChapter(i)"></i></span>
            <span class="mx-2 delete-tooltip"><i class="fas fa-trash" @click.prevent="deleteChapter(item)"></i></span>
          </div>
          <div class="syllabus__box syllabus__section d-flex align-items-center p-3 ms-5 mb-2 position-relative"
            v-for="(sec, j) in item.section" :key="sec.id">
            <span class="mx-2" v-if="sec.lock"
              @click.prevent="editSection(item, sec);tempSection.lock = false;finishSection(item.id)">
                <i class="fas fa-lock me-1"></i>
            </span>
            <span class="mx-2" v-if="!sec.lock"
              @click.prevent="editSection(item, sec);tempSection.lock = true;finishSection(item.id)">
              <i class="fas fa-unlock"></i>
            </span>
            <span>單元 {{ j + 1 }}</span>
            <span class="text-start mx-2"
              @mouseover="Showtooltip = sec.id"
              @mouseleave="Showtooltip = ''"
              style="flex: 1 1 0%;display: inline-block;height: 1.5rem;line-height: 1.5rem;" v-if="tempSyllabus.id !== item.id || tempSection.id !== sec.id"
              @dblclick.prevent="editSection(item, sec);Showtooltip = ''">
              {{ sec.title }}
            </span>
            <span v-if="Showtooltip == sec.id" class="position-absolute edit-tooltip">
              點擊兩下以編輯
            </span>
            <input type="text" style="flex: 1 1 0%;border:none;"
              v-if="tempSyllabus.id == item.id && tempSection.id === sec.id"
              class="mx-2 px-0"
              v-model="tempSection.title"
              @change="finishSection(item.id);change = true">
            <span class="mx-2 modal-tooltip" data-bs-target="#editChapterModal" data-bs-toggle="modal"
              @click.prevent="openModal(item.id, sec.id, sec.videoUrl, sec.videoId, sec.lock, sec.desc, sec.time, sec.title)">
              <i class="fas fa-edit"></i>
            </span>
            <!-- <span class="mx-2"><i class="fas fa-check" @click.prevent="finishSection(item.id)"></i></span> -->
            <span class="mx-2 section-tooltip"><i class="fas fa-plus" @click.prevent="addSection(item.id, j)"></i></span>
            <span class="mx-2 delete-tooltip"><i class="fas fa-trash" @click.prevent="deleteSection(item, sec)"></i></span>
            <!-- <span class="mx-2"><i class="fas fa-plus" @click.prevent="addSub=sec.id"></i></span> -->
            <!-- <Test v-if="addSub==sec.id"></Test> -->
          </div>
        </div>
      </div>
      <span v-if="emptyError" class="text-danger">{{ emptyError }}</span>
      <div class="d-flex justify-content-start align-items-center editBox" ref="editBox">

        <button class="btn btn-main mt-3 w-25 me-2" v-if="mode == 'launch'" @click.prevent="toLastStep">上一步</button>

        <button class="btn btn-main mt-3 w-25" @click.prevent="save">{{ mode == 'launch'? '下一步' : '儲存' }}</button>
        <a class="editCourse__back mt-3 w-50 text-start" style="margin-left: 1rem;" v-if="mode == 'edit'" @click.prevent="backToMyCourse">
          返回至我的課程頁<i class="fas fa-chevron-right ms-1"></i>
        </a>
      </div>
      <!-- 浮動buttons -->
      <div class="d-flex justify-content-start align-items-center editCourse__saveFixed p-4" v-if="showBtn">

        <button class="btn btn-main mt-3 w-25 me-2" v-if="mode == 'launch'" @click.prevent="toLastStep">上一步</button>

        <button class="btn btn-main mt-3" @click.prevent="save">{{ mode == 'launch'? '下一步' : '儲存' }}</button>
        <a class="editCourse__back mt-3 text-start" style="margin-left: 1rem;" v-if="mode == 'edit'" @click.prevent="backToMyCourse">
          返回至我的課程頁<i class="fas fa-chevron-right ms-1"></i>
        </a>
      </div>

    </div>
    <div class="modal fade editChapter" id="editChapterModal" tabindex="-1" data-bs-focus="false" aria-labelledby="editChapterModalLabel" aria-hidden="true" data-bs-backdrop="static">
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="position-absolute" style="left: 0; top: 0;width: 100%; height: 100%;z-index: 20;background-color: white;opacity: .5;" v-if="readyUpload">
            <img class="position-absolute" style="left: 50%;top: 50%;transform: translate(-50%, -50%);" src="../assets/images/square-loader.gif" alt="" >
          </div>
          <div class="modal-header border-0 pb-0">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-start">
            <h3 class="text-start fw-bold mb-2 evaluation__title" style="font-size: 1.2rem;">影片位址</h3>
              <ValidationProvider rules='http' v-slot='{ errors , classes }'>
                <div :class="classes" class="position-relative">
                  <input type="text" placeholder="https://player.vimeo.com/video/"
                    class="editChapter__input"
                    v-model="tempLink"
                    readonly
                    @focus="editing = true">
                  <span class="text-danger mt-1">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            <!-- <span v-if="editing == false" @dblclick="editing = true">{{ tempLink }}</span> -->
              <!-- <button class="btn btn-main d-block mt-4">開始上傳檔案</button> -->
              <form class="mt-4 d-flex">
                <label for="file" class="form-label"></label>
                <input class="form-control w-50" type="file" id="file" name="file_data" ref="file" @change="testCreateVideo()">
              </form>
              <small style="font-size: .8rem; font-weight: normal;">(影片檔案格式：MP4, MOV, WMV, AVI, FLV)</small>
              <br>
              <button class="btn btn-main mt-4" v-if="ManualVideoUpload" @click.prevent="openPaster">貼上既有影片網址</button>
              <p v-if="pastedLinkSuccess">請稍候...</p>
              <!-- <div class="d-flex align-items-center mt-3" v-if="startCreate">
                <div class="upload__progress me-2">
                  <div class="upload__progressBar" :style="{ 'width': percentage + '%'}"></div>
                </div>
                <p>{{ percentage }}% 上傳中，請勿關閉視窗</p>
              </div> -->
              <div class="mt-4">
                <h3 class="mb-2 fw-bold">影片長度</h3>
                <div>
                  <input type="number" name="hour" size="2" max="10" min="0"
                    class="form-control d-inline-block me-2"
                    style="width: unset;"
                    placeholder="0"
                    v-model="hour"
                    readonly
                    >
                  <span class="me-3">小時</span>
                  <input type="number" name="min" size="2" max="60" min="0"
                    class="form-control d-inline-block me-2"
                    style="width: unset;"
                    placeholder="0"
                    ref="min"
                    v-model="min"
                    readonly
                    >
                  <span class="me-3">分鐘</span>
                  <input type="number" name="sec" size="2" max="60" min="0"
                    class="form-control d-inline-block me-2"
                    style="width: unset;"
                    placeholder="0"
                    ref="sec"
                    v-model="sec"
                    readonly
                    >
                  <span>秒</span>
                </div>
                <!-- <span class="invalid text-danger mt-1" v-if="timeError" @change="timeError = ''">{{ timeError }}</span> -->
              </div>
              <h3 class="mt-4 fw-bold">內容描述 <small style="font-size: .8rem; font-weight: normal;">(請填寫該單元相關資訊，800字內)</small></h3>
              <quill-editor class="ql-editor"
                v-model="tempContent"
                ref="myQuillEditor"
                :options="editorOption"
                >
              </quill-editor>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
            <button type="button" class="btn btn-main" data-bs-dismiss="modal" @click.prevent="saveLink">儲存</button>
          </div>
        </div>
      </div>
    </div>
    <Meta :title ="metaTitle" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import * as tus from 'tus-js-client';
import Steps from '../components/Steps';
import Test from '../components/test';
import FileAlert from '../components/FileAlert';

const Swal = require('sweetalert2');

/*  [{
    id: '',
    title: '',
    video: '',
    desc: '',
    lock: true,
    section: [
      {
        id: '',
        title: '',
        video: '',
        desc: '',
        lock: true,
      },
      {
        id: '',
        title: '',
        video: '',
        desc: '',
        lock: true,
      }
    ]
  }] */

export default {
  name: 'Edit2',
  data() {
    return {
      step: 2,
      link: '/dashboard/edit_course/syllabus',
      editorOption: {
        modules: {
          toolbar: {
            container: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }], ['bold'],
              [{ list: 'ordered' }, { list: 'bullet' }], ['link', 'image'],
            ],
          },
        },
        theme: 'snow',
      },
      tempSyllabus: {
        id: '',
        title: '',
        section: [{
          id: '',
          title: '',
          videoUrl: '',
          videoId: '',
          desc: '',
          lock: true,
          time: '',
        }],
      },
      tempSection: {
        id: Math.floor(Date.now()),
        title: '',
        videoUrl: '',
        videoId: '',
        desc: 'content',
        lock: true,
        time: '',
      },
      // syllabus: []
      tempSyll: [],
      chapterId: '',
      sectionId: '',
      tempLink: '',
      editing: false,
      templock: true,
      addSub: '',
      tempContent: '',
      hour: 0,
      min: 0,
      sec: 0,
      tempTime: 0,
      Showtooltip: null,
      mode: 'launch',
      goNext: false,
      emptyError: '',
      id: '',
      change: false,
      showBtn: false,
      uploadLink: '',
      vimeoUrl: '',
      readyUpload: false,
      disabled: false,
      tempTitle: '',
      tempVideoId: '',
      uploadArr: [],
      metaTitle: '編輯課程 - 元學堂',
      pastedLinkSuccess: false,
      allowPaste: false,
    };
  },
  components: {
    Steps,
    Test,
    FileAlert,
  },
  metaInfo: {
    title: "編輯課程 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '編輯課程 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  methods: {
    async openPaster() {
      // input for link pasted
      const { value: fruit } = await Swal.fire({
        title: '輸入影片網址',
        input: 'text',
        inputValue: '',
        inputLabel: '影片網址',
        inputPlaceholder: 'https://player.vimeo.com/video/735690297?h=2dfd01d146',
        showCancelButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '確定',
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (/(http(s?)):\/\/player.vimeo.com\/video/i.test(value)) {
              resolve()
            } else {
              resolve('請輸入有效連結')
            }
          })
        }
      })
      // api: get id then set tempVideoId and set pasted link as tempLink
      if (fruit) {
        this.pastedLinkSuccess = true;
        this.$http.post(`${this.$API_PATH}/Vimeo/Manual`,
        { existedVimeoEmbbedUrl: fruit},
        {
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: `bearer ${this.userAuth}`,
          }
        }).then((res) => {
          this.pastedLinkSuccess = false;
          this.tempVideoId = res.data.data.vimeoVideoId;
          this.tempLink = fruit;
          // console.log(this.tempLink, this.tempVideoId);
        }).catch((err) => {
          this.pastedLinkSuccess = false;
          if (err.response) {
            if (err.response.status == 400) {
              this.$log.debug(err.response);
              window.alert('格式錯誤，請輸入正確的 Vimeo 嵌入網址');
            }
          } else {
            this.$log.debug(err.response);
            window.alert('錯誤，請稍後再試。');
          }
          
        });
      }
    },
    testCreateVideo() {
      const vm = this;
      const uploadedFile = this.$refs.file.files[0];
      this.readyUpload = true;
      // console.log(this.$refs.file.files);
      if (!this.$refs.file.files[0]) {
        this.readyUpload = false;
        window.alert('請上傳mp4影片檔案');
        return;
      } else if (!uploadedFile['type'].includes('video')) {
        this.readyUpload = false;
        window.alert('請上傳mp4影片檔案');
        return;
      }
      vm.$log.debug(uploadedFile);

      this.$http.post(`${this.$API_PATH}/Vimeo`, {
        VideoSize: uploadedFile.size,
      }, {
        headers: {
          Accept: '*/*',
        },
      }).then((res) => {
        vm.$log.debug(res.data);
        vm.uploadLink = res.data.data.uploadLink;
        vm.vimeoUrl = res.data.data.videoEmbedUrl;
        vm.tempVideoId = res.data.data.id;
        vm.tempLink = vm.vimeoUrl;
        vm.readyUpload = false;
        vm.uploadArr.unshift({
          uploadLink: vm.uploadLink,
          vimeoUrl: vm.vimeoUrl,
          id: vm.tempVideoId,
          percentage: null,
        });

        let i;
        let j;
        vm.tempSyll.forEach((el, idx) => {
          if (el.id === vm.chapterId) {
            i = idx;
          }
        });
        vm.tempSyll[i].section.forEach((el, idx) => {
          if (el.id === vm.sectionId) {
            j = idx;
          }
        });
        vm.tempSyll[i].section[j].videoUrl = vm.tempLink;
        vm.tempSyll[i].section[j].videoId = vm.tempVideoId;
        vm.$store.dispatch('syllabusModule/getSyllData', vm.tempSyll);

        var upload = new tus.Upload(uploadedFile, {
          uploadUrl: this.uploadLink,
          storeFingerprintForResuming: true,
          fingerprint(file, options) {
            const value = this.uploadLink;
            return Promise.resolve(value);
          },
          onError(error) {
            vm.$log.debug(`Failed because: ${error}`);
            // vm.readyUpload = false;
            Swal.fire(
              '',
              '上傳出現錯誤，請再試一次',
              'info',
            );
            vm.disabled = false;
          },
          onProgress(bytesUploaded, bytesTotal) {
            // vm.percentage = (bytesUploaded / bytesTotal * 100).toFixed(0);
          // vm.$log.debug(bytesUploaded, bytesTotal, vm.percentage + "%");
            vm.uploadArr.forEach((item, i) => {
              if (item.uploadLink == upload.url) {
                item.percentage = `${(bytesUploaded / bytesTotal * 100).toFixed(0)}%`;
              }
            });
          },
          onSuccess() {
            vm.$log.debug('Download %s from %s', upload.file.name, upload.url);
            let check = false;
            vm.uploadArr.forEach((item, i) => {
              if (item.uploadLink == upload.url) {
                item.percentage = 'complete';
              }
              if (vm.tempLink == upload.url) {
                check = true;
              }
            });
            const nowModal = vm.uploadArr.filter((item) => vm.tempLink == item.uploadLink);
            // vm.readyUpload = !check;
            // vm.readyUpload = nowModal.length != 0;
            if (nowModal.length == 0) {
              // vm.readyUpload = false;
            }
            vm.$log.debug('Download %s from %s', upload.file.name, upload);
            vm.disabled = false;
          },
        });
        // Start the upload
        upload.start();
      }).catch((err) => {
        vm.$log.debug(err.response);
        this.readyUpload = false;
        window.alert('錯誤，請稍後再試。');
      });
    },
    removeMessage() {
      this.uploadArr = [];
    },
    addChapter(idx) {
      this.tempSyll.splice(idx + 1, 0, {
        id: Math.floor(Date.now()),
        title: '',
        section: [{
          id: Math.floor(Date.now() + 10),
          title: '',
          video: '',
          videoUrl: '',
          desc: '',
          lock: true,
          time: '',
        }],
      });
      this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
    },
    editChapter(item) {
      this.tempSyllabus = item;
    },
    editSection(item, sec) {
      this.tempSyllabus = item;
      this.tempSection = sec;
    },
    save() {
      const vm = this;
      vm.goNext = true;

      // get syllabus data from local
      // this.getDatafromLocal();
      // handle data format

      vm.emptyError = '';
      let chapterTitleErr = [];
      const chapters = this.tempSyll.map((el, i) => {
        if (!el.title) {
          chapterTitleErr.push(i + 1);
        }
        if(chapterTitleErr.length > 0) {
          vm.emptyError = `第 ${chapterTitleErr.join(',')}章節名稱未填～`;
        }
        return { title: el.title, chaperItem: el.section };
      });

      // 檢查單元標題、影片
      let unitTitleErr = []; // 單元序列array
      let unitChapterErr = []; // 章節序列array
      chapters.forEach((item, idx) => {
        item.chaperItem.forEach((el, i) => {
          if (!el.title || !el.videoId) {
            unitTitleErr.push(i + 1);
            unitChapterErr.push(idx + 1);
            vm.emptyError += `第 ${idx + 1}章節 單元${i + 1} 欄位不完整～`;
          }
        })
      });
      chapters.forEach((item, idx) => {
        const chapterArr = item.chaperItem.map((el, i) => {

          return {
            title: el.title, videoId: el.videoId, lock: el.lock, content: el.desc,
          };
        });
        item.chaperItem = [...chapterArr];
      });
      this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
      vm.$log.debug(chapters);
      if (vm.mode == 'edit') {
        const data = { ...vm.course, chapter: chapters };
        vm.$log.debug(data);
        vm.$http.put('https://api1.metalearning.com.tw/Course', data, {
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: `bearer ${vm.userAuth}`,
          },
        }).then((res) => {
          vm.goNext = true;
          vm.emptyError = '';
          Swal.fire(
            '儲存成功',
            `${res.data.message}`,
            'success',
          );
        }).catch((err) => {
          vm.$log.debug(err.response);
          if (err.response) {
            Swal.fire(
              '',
              `${err.response.data.message}`,
              'info',
            );
          } else {
            Swal.fire(
              '錯誤',
              '請稍候再試',
              'info',
            );
          }
        });
      } else {
        // this.getDatafromLocal();
        // let course = JSON.parse(localStorage.getItem('Course'));
        // course.chapter = chapters;
        // localStorage.setItem('Course', JSON.stringify(course));
        //vm.emptyError = '';
        this.$store.dispatch('courseModule/setCourseData', { chapter: chapters });
        this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
        if(vm.emptyError == '') {
          vm.$router.push('/dashboard/edit_course/pricing');
        }
        
      }
    },
    toLastStep() {
      const vm = this;
      vm.goNext = true;
      vm.$router.push('/dashboard/edit_course/basic');
    },
    addSection(id, idx) {
      let i;
      this.tempSyll.forEach((el, idx) => {
        if (el.id === id) {
          i = idx;
        }
      });
      this.tempSyll[i].section.splice(idx + 1, 0, {
        id: Math.floor(Date.now()),
        title: '',
        videoUrl: '',
        videoId: '',
        desc: '',
        lock: true,
        time: '',
      });
      this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
    },
    finishChapter() {
      let i;
      this.tempSyll.forEach((el, idx) => {
        if (el.id === this.tempSyllabus.id) {
          i = idx;
        }
      });
      this.tempSyll[i] = this.tempSyllabus;
      this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
      // localStorage.setItem('syllabus', JSON.stringify(this.syllabus));
      // this.$log.debug(this.syllabus);
    },
    finishSection(id) {
      let i;
      let j;
      this.$log.debug(id);
      this.tempSyll.forEach((el, idx) => {
        this.$log.debug(el.id);
        if (el.id === id) {
          i = idx;
        }
      });
      this.$log.debug(i);
      this.tempSyll[i].section.forEach((el, idx) => {
        if (el.id === this.tempSection.id) {
          j = idx;
        }
      });
      this.tempSyll[i].section[j] = this.tempSection;
      // localStorage.setItem('syllabus', JSON.stringify(this.syllabus));
      this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
      // this.getDatafromLocal();
      this.$log.debug(this.syllabus);
    },
    deleteChapter(item) {
      let i;
      this.tempSyll.forEach((el, idx) => {
        if (el.id === item.id) {
          i = idx;
        }
      });
      if (this.tempSyll.length == 1) {
        return;
      }
      this.tempSyll.splice(i, 1);
      // localStorage.setItem('syllabus', JSON.stringify(this.syllabus));
      this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
      // this.getDatafromLocal();
      this.$log.debug(this.syllabus);
    },
    deleteSection(item, sec) {
      let i;
      let j;
      this.tempSyll.forEach((el, idx) => {
        this.$log.debug(item.id);
        if (el.id === item.id) {
          i = idx;
        }
      });
      this.$log.debug(i);
      this.tempSyll[i].section.forEach((el, idx) => {
        if (el.id === sec.id) {
          j = idx;
        }
      });
      this.tempSyll[i].section.splice(j, 1);
      // localStorage.setItem('syllabus', JSON.stringify(this.syllabus));
      // this.getDatafromLocal();
      this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
      // this.$log.debug(this.syllabus);
    },
    openModal(chapterId, sectionId, link, VideoId, lock, content, time, title) {
      this.chapterId = chapterId;
      this.sectionId = sectionId;
      this.tempLink = link;
      // if(this.tempLink!== '') {
      //   this.$http.get(`https://api.vimeo.com/videos/${this.tempLink.split('/')[4].split('?')[0]}?fields=duration`,
      //     {
      //     headers: {
      //       'Authorization': `bearer 6e21a5e7a50f2160076af90e1f85ebea`,
      //     }
      //   }).then(res => {
      //     console.log(res.data);
      //   }).catch(err => {

      //   })
      // }
      this.tempTime = time;
      this.hour = this.tempTime.hour;
      this.min = this.tempTime.min;
      this.sec = this.tempTime.sec;
      this.tempVideoId = VideoId;
      this.templock = lock;
      this.tempContent = content;
      this.tempTitle = title;
      this.$refs.file.value = null;
      // const check = this.uploadArr.filter((item, i) => item.vimeoUrl == this.tempLink);
      // if (check.length > 0 && check[0].percentage !== 'complete') {
      //   this.readyUpload = true;
      // } else {
      //   this.readyUpload = false;
      // }

      this.disabled = false;
      this.$log.debug(this.tempLink);
    },
    saveLink() {
      let i;
      let j;
      this.tempSyll.forEach((el, idx) => {
        if (el.id === this.chapterId) {
          i = idx;
        }
      });
      this.$log.debug(i);
      this.tempSyll[i].section.forEach((el, idx) => {
        if (el.id === this.sectionId) {
          j = idx;
        }
      });
      this.tempSyll[i].section[j].videoUrl = this.tempLink;
      this.tempSyll[i].section[j].videoId = this.tempVideoId;
      this.tempSyll[i].section[j].lock = this.templock;
      this.tempSyll[i].section[j].desc = this.tempContent;
      this.tempSyll[i].section[j].time = this.tempTime;
      // localStorage.setItem('syllabus', JSON.stringify(this.syllabus));
      // this.getDatafromLocal();
      this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
    },
    formatTime(time) {
      const timeArr = time.split(':');
      if (timeArr.length == 2) {
        this.hour = 0;
        this.min = Number(timeArr[0]);
        this.sec = Number(timeArr[1]);
      } else {
        this.hour = Number(timeArr[0]);
        this.min = Number(timeArr[1]);
        this.sec = Number(timeArr[2]);
      }
    },
    getDatafromLocal() {
      this.syllabus = JSON.parse(localStorage.getItem('syllabus')) || [];
    },
    getEditData() {
      this.updateLoading(true);
      this.$http.get(`${this.$API_PATH}/Course?CourseId=${this.id}`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.$log.debug(res.data.data);
        if (res.data.data) {
          // this.editData = res.data.data;
          this.$store.dispatch('courseModule/getEditData', res.data.data);
          this.$store.dispatch('courseModule/setCourseData', { trailVideoId: res.data.data.trailerVideoId });
          this.tempSyll = this.course.chapter.map((item, i) => ({ id: i * 2, title: item.title, section: item.chaperItem }));
          this.tempSyll.forEach((item, idx) => {
            const sectionArr = item.section.map((el, i) => ({
              id: `${idx}-${i}`, title: el.title, videoUrl: el.videoUrl, videoId: el.videoId, desc: el.content, lock: el.lock, time: el.duration,
            }));
            item.section = [...sectionArr];
          });
          this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
          // console.log(this.syllabus);
          if (this.tempSyll.length == 0) {
            this.tempSyll.push({
              id: Math.floor(Date.now()),
              title: '',
              section: [{
                id: Math.floor(Date.now() + 10),
                title: '',
                videoUrl: '',
                videoId: '',
                desc: '',
                lock: true,
                time: '',
              }],
            });
            this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
          }
        }
        this.updateLoading(false);
      }).catch((err) => {
        this.goNext = true;
        this.updateLoading(false);
        this.$router.push('/error');
        this.$log.debug(err.response);
      });
    },
    backToMyCourse() {
      this.$router.push('/dashboard/courses');
    },
    mouseMove(e) {
      const vm = this;
      let posx = 0;
      let posy = 0;
      if (!e) var e = window.event;
      if (e.pageX || e.pageY) {
        posx = e.pageX - document.documentElement.scrollLeft - document.body.scrollLeft;
        posy = e.pageY - document.documentElement.scrollTop - document.body.scrollTop;
      } else if (e.clientX || e.clientY) {
        posx = e.clientX;// + document.body.scrollLeft+ document.documentElement.scrollLeft;
        posy = e.clientY;// + document.body.scrollTop + document.documentElement.scrollTop;
      }
      if (posy > (window.innerHeight - 120)) {
        vm.showBtn = true;
      } else if (posy <= (window.innerHeight - 120)) {
        vm.showBtn = false;
      }
      const editBox = document.querySelector('.editBox');
      const slideInAt = (window.scrollY + window.innerHeight) - editBox.offsetHeight / 2;
      if (slideInAt > editBox.offsetTop) {
        vm.showBtn = false;
      }
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider', 'ManualVideoUpload']),
    ...mapGetters('courseModule', ['course']),
    ...mapGetters('syllabusModule', ['syllabus']),
  },
  beforeRouteLeave(to, from, next) {
    const vm = this;
    if (vm.goNext || vm.change == false) {
      next();
    } else {
      let text = '';
      if (this.mode == 'edit') {
        text = '尚未儲存這次的更新，請按儲存以儲存您的修改。';
      } else {
        text = '提醒您，上架課程將不會暫存您的變更，需完成至第四步驟完成發布，本系統即會儲存並發布您的課程。';
      }
      Swal.fire({
        title: '',
        text,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: '取消',
        confirmButtonText: '離開',
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        }
      });
    }
  },
  created() {
    this.$log.debug(this.min);
    this.$log.debug(this.sec);
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    this.$http.get(`${this.$API_PATH}/Member/Profile`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        // this.user.account = res.data.data.account;
        if(res.data.data.email) {
          this.allowPaste = true;
        } else {
          this.allowPaste = false;
        }
      }).catch((err) => {
        // this.$router.push('/error');
        console.log(err);
      });
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.mode = 'edit';
      this.getEditData();
    } else {
      // this.getDatafromLocal();
      this.$log.debug(this.syllabus);
      localStorage.setItem('step', '2');
      if (this.syllabus.length == 0 || !this.syllabus) {
        this.$store.dispatch('syllabusModule/getSyllData', [{
          id: Math.floor(Date.now()),
          title: '',
          section: [{
            id: Math.floor(Date.now() + 10),
            title: '',
            videoUrl: '',
            videoId: '',
            desc: '',
            lock: true,
            time: '',
          }],
        }]);
      }
      this.tempSyll = this.syllabus;
    }
  },
  mounted() {
    const vm = this;
    window.addEventListener('beforeunload', (e) => {
      const confirmationMessage = '你還沒有完成你的文章，就這樣離開了嗎？';
      localStorage.removeItem('syllabus');

      (e || window.event).returnValue = confirmationMessage; // Gecko + IE
      return confirmationMessage; // Webkit, Safari, Chrome
    });
    window.addEventListener('mousemove', vm.mouseMove);
  },
  destroyed() {
    const vm = this;
    window.removeEventListener('beforeunload', (e) => {
      const confirmationMessage = '你還沒有完成你的文章，就這樣離開了嗎？';
      localStorage.removeItem('syllabus');

      (e || window.event).returnValue = confirmationMessage; // Gecko + IE
      return confirmationMessage; // Webkit, Safari, Chrome
    });
    window.removeEventListener('mousemove', vm.mouseMove);
  },
};
</script>

<style lang="scss" scoped>
  .invalid .text-danger {
    text-align: left;
    width: 100%;
    display: block;
    font-size: 12px;
  }

  .invalid input {
    border-bottom: 1px #EB0600 solid;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(252, 67, 21, 0.25);
    }
  }

  .invalid.text-danger {
    text-align: left;
    width: 100%;
    display: block;
    font-size: 12px;
  }
  .upload {
    &__progress {
      background: rgb(197, 197, 197);
      width: 6rem;
      height: .8rem;
      border-radius: 10px;
    }
    &__progressBar {
        background: #012970;
        width: 0%;
        height: inherit;
        border-radius: 10px;
      }
  }
  ::v-deep .ql-editor strong {
    font-weight: bold;
  }
  .testhover {
    position: relative;
    &::after {
      position: absolute;
      content: '新增章節';
      top: -1.5rem;
      left: -2rem;
      width: 4rem;
      height: 1.1rem;
      font-size: 14px;
      background: #ffffff;
      color: black;
      z-index: 3;
      border-radius: 5px;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
      opacity: 0;
    }
    &:hover.testhover::after {
      opacity: 1;
    }
  }
  .section-tooltip {
    position: relative;
    &::after {
      position: absolute;
      content: '新增單元';
      top: -1.5rem;
      left: -2rem;
      width: 4rem;
      height: 1.1rem;
      font-size: 14px;
      background: #ffffff;
      color: black;
      z-index: 3;
      border-radius: 5px;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
      opacity: 0;
    }
    &:hover.section-tooltip::after {
      opacity: 1;
    }
  }
  .delete-tooltip {
    position: relative;
    &::after {
      position: absolute;
      content: '刪除';
      top: -1.5rem;
      left: -1rem;
      width: 3rem;
      height: 1.1rem;
      font-size: 14px;
      background: #ffffff;
      color: black;
      z-index: 3;
      border-radius: 5px;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
      opacity: 0;
    }
    &:hover.delete-tooltip::after {
      opacity: 1;
    }
  }

  .modal-tooltip {
    position: relative;
    &::after {
      position: absolute;
      content: '編輯';
      top: -1.5rem;
      left: -1rem;
      width: 3rem;
      height: 1.1rem;
      font-size: 14px;
      background: #ffffff;
      color: black;
      z-index: 3;
      border-radius: 5px;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
      opacity: 0;
    }
    &:hover.modal-tooltip::after {
      opacity: 1;
    }
  }
</style>
