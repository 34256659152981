<template>
  <div class="message-alert" :class="{'folded': folded === true }">
    <div class="message-title p-3 text-white text-start d-flex justify-content-between">
      <span>上傳項目</span>
      <div class="d-flex align-items-center">
        <span class="message-fold" @click="folded = !folded;">
          <i class="fas fa-angle-down" v-if="!folded"></i>
          <i class="fas fa-angle-up" v-if="folded"></i>
        </span>
        <span @click="removemessage" class="message-close ms-3 fw-bold">X</span>
      </div>
    </div>
    <div class="message-box">
      <div v-for="(item, i) in uploadArr" :key="i" class="d-flex p-3 align-items-center justify-content-between message">
        <p style="font-size: 10px;">{{ item.vimeoUrl }}</p>
        <p v-if="item.percentage == null">上傳中</p>
        <p v-if="item.percentage == 'complete'">完成</p>
        <p v-else>{{ item.percentage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileAlert',
  props: ['uploadArr'],
  data() {
    return {
      folded: false,
    };
  },
  methods: {
    removemessage() {
      this.$emit('removeMessage');
    },
  },
};
</script>

<style scope lang="scss">
.message-alert {
  position: fixed;
  width: 430px;
  bottom: 8%;
  right: 1%;
  z-index: 1100;
  background: white;
  border-radius: 3px;
  height: 160px;
  box-shadow: rgba(0, 0, 0, .3) 1px 1px 10px;
  overflow: scroll;
  transition: height .3s;
  &.folded {
    height: 55.4px;
    overflow: hidden;
  }
  @media screen and (max-width: 576px) {
    width: 95%;
    bottom: 2%;
    right: 10px;
  }
}

.message {
  border-bottom: 1px solid rgb(218, 218, 218);

  &-close {
    cursor: pointer;
  }
  &-title {
    background-color: #012970;
  }
  &-fold {
    font-size: 1.4rem;
  }
}
</style>
