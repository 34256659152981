import { render, staticRenderFns } from "./FileAlert.vue?vue&type=template&id=885fe2f2"
import script from "./FileAlert.vue?vue&type=script&lang=js"
export * from "./FileAlert.vue?vue&type=script&lang=js"
import style0 from "./FileAlert.vue?vue&type=style&index=0&id=885fe2f2&prod&scope=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports